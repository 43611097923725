body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body, html {
  min-height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: #000;
  background-image: url('../public/background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(244,243,241);
  background-attachment: fixed;
}

button {
  margin: 0;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;

}

h2 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}


h4 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

ul, ol {
  padding-left: 16px;
}

h5 {
  font-size: 14px;
  font-weight: 300;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}

input[type=text], input[type=button], input[type=submit] {   
  background-color : #D3D3D3;    
}

input:active,
input:focus, button:active {
  outline: 1px auto rgba(255, 255, 255, 0.3) !important;
  outline-offset: 0px;
}

#root, .App {
  min-height: 100vh;
}

hr {
  border: none;
  border-bottom: rgb(244,243,241) solid 1px;
  margin: 30px 0;
}