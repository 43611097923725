.login-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px 15px;
    text-align: center;
    height: 100vh;
}
.login-wrapper h1 {
    margin-bottom: 12px;
}
.login-wrapper h4 {
    margin-bottom: 8px;
}

.logo {
    max-width: 300px;
    width: 100%;
    margin-bottom: 30px;
}

.login-form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error {
    color: #ff9aa2;
    margin: 8px 0;
    height: 30px;
}

.login-form input, .login-form button {
    width: 100%;
    max-width: 400px;
    font-size: 18px;
    font-weight: 700;
    color: rgb(244,243,241);
    text-align: center;
    padding: 5px;
    margin: 0;
    border: none;
    border-radius: 20px;
    margin: 5px 0;
}

.login-form input {
    background-color: rgb(238, 207, 179, 0.5);
}

.login-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b0b0b0;
  opacity: 1; /* Firefox */
}
  
.login-form button {
    color: #b0b0b0;
    background-color: #0e2045;
    border: 1px #565656 solid;
    cursor: pointer;
}
