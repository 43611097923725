.video-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
}

.videoplayer {
    /* display: flex;
    flex-direction: column; */
    /* align-items: flex-end; */
    /* justify-content: center;
    flex: 1 1; */
}
.vjs-play-progress.vjs-slider-bar::before {
    display: none;
}
.video-js {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    max-height: calc(100vh - 250px);

}
.vjs-poster {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.video-js video {
    background-color: black;

}


.header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.header .logo {
    margin-right: 8px;
    margin-top: 2px;
    max-width: 150px;
}
.filler {
    height: 13%;
    max-height: 150px;
}

.video {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.video video {
    width: 100%;
}

.video header {
    padding: 32px 20px 12px;
}

.video header .info {
    color: #aab3c7;
    text-align: right;
}

.video header .info>* {
    margin-bottom: 8px;
}

.agenda {
    padding: 32px 20px;
    max-width: 600px;
    border-left: #aab3c7 4px solid;
    overflow: scroll;
    background: rgba(0,0,0,0.5);
}
.agenda h1 {
    color: #aab3c7;
}

.session-title {
    margin-bottom: 4px;
    font-weight: 700;
}

.session-speakers {
    display: flex;
    flex-direction: column;
}
.session-speaker {
    margin: 4px 0;
    font-size: 12px;
}
.lang {
    width: 100%;
    letter-spacing: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    padding: 0 20px;
    padding-bottom: 20px;
    border-top: #727690 4px solid;
}
.lang button {
    font-size: 16px;
    color: rgb(244,243,241);
    background-color: #2c2a4b;
    border: none;
    padding: 2px 20px;
    cursor: pointer;
}
.lang button:disabled {
    text-decoration: none;
    background-color: #727690;
    cursor: default;
}

li {
    margin-bottom: 12px;
}

@media only screen and (max-width: 800px) {
    .header .logo {
        width: 100px;
    }
    .video-wrapper {
        flex-direction: column;
        min-height: 100vh;
        display: block;
        
    }
    .videoplayer {
        padding-bottom: 0;
    }

    .agenda {
        max-width: 100%;
        overflow: auto;
        height: auto;
    }
}

.vjs-play-progress.vjs-slider-bar .vjs-time-tooltip {
    display: none !important;
}


.video-js .vjs-progress-control:hover .vjs-marker-tooltip {
    display: block;
}
.vjs-time-tooltip {
    display: none;
}

.vjs-tip {
    bottom: 40px !important;
}

.vjs-tip .vjs-tip-inner {
    padding: 12px !important;
}

.session-time {
    font-size: 12px;
    color: #72d5ff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 4px;

}
.session-time:before {
    content: '[';
    margin-right: 2px;
}
.session-time:after {
    content: ']';
    margin-left: 2px;
}
.session-time:active {
    outline: none !important;
}
.session-toggle {
   padding-left: 4px;
}

.session-toggle.active {
    background-color: rgba(44, 42, 75, 0.8);
}

.vjs-remaining-time {
    min-width: 70px !important;
}